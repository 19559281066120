$(() => {
    initGoogleClick();
});

function initGoogleClick() {
    let googleClickId = new URLSearchParams(window.location.search).get('gclid');
    if (googleClickId) {
        let gclidString = JSON.stringify({
            value: googleClickId,
            timestamp: new Date().getTime()
        });
        window.localStorage.setItem('googleClickId', gclidString);
    }
}
